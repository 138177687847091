import React from "react";

import { graphql } from "gatsby";

import Layout from "../components/Layout";
import View from "../components/Section";

const NotFound = (props) => (
	<Layout location={props.location}>
		<View title="Not Found">
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</View>
	</Layout>
);

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;